import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ActionFunctionArgs,
  useLoaderData,
  useNavigate,
  useNavigation,
  useSearchParams,
} from "react-router-dom";

import { Card, CardContent, CardHeader, Spinner, Text } from "@new-black/lyra";
import { state } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import { ErrorFallback } from "~/components/shared/error-fallback";
import { LogoIcon } from "~/components/shared/header/logo";
import { Page } from "~/components/shared/page";
import PageCenter from "~/components/shared/page-center";
import { TitleHelmet } from "~/components/suite-composite/title-helmet";
import UIErrorBoundary from "~/components/suite-ui/error-boundary";
import { Login } from "~/features/login";
import { confirm2FA, loginByCredentials } from "~/features/login/helpers";
import { getApplicationConfigurationLoaderQuery } from "~/models/application";
import { getAvailableOpenIDConfigurationsLoaderQuery } from "~/models/login";
import { InferLoaderOrActionReturnType } from "~/types/loaders.types";
import { ONE_HOUR } from "~/util/base-values";
import { queryClient } from "~/util/query-client";

export function Component() {
  const setUserTokenState = useSetRecoilState(state.currentUser.currentUserTokenState);
  const intl = useIntl();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onLogin = () => {
    const from = searchParams.get("from");
    navigate(from ?? "/", { replace: true });
  };

  const loaderData = useLoaderData() as LoginLoaderData;

  // effect that needs to run if the previous location was logout.
  useEffect(() => {
    if (searchParams.get("logout") === "true") {
      setUserTokenState("");
    }
  }, [setUserTokenState, searchParams]);

  const { state: navigationState } = useNavigation();

  return (
    <>
      <TitleHelmet
        title={intl.formatMessage({ id: "page.login.title", defaultMessage: "Login" })}
      />

      <Page>
        <PageCenter>
          <div className="mx-4 w-[360px] max-w-full">
            <div className="mb-12 flex justify-center">
              <div className="flex justify-center">
                <LogoIcon className="text-primary" width={121} height={46} />
              </div>
            </div>

            <Card>
              <UIErrorBoundary>
                {navigationState === "loading" && (
                  <div className="flex min-h-[250px] flex-col items-center justify-center gap-5 p-5">
                    <Spinner size="large" />
                    <Text className="font-semibold">
                      <FormattedMessage
                        id="generic.label.loading-environment"
                        defaultMessage="Loading environment..."
                      />
                    </Text>
                  </div>
                )}
                {navigationState !== "loading" ? (
                  <>
                    <CardHeader
                      title={intl.formatMessage({
                        id: "generic.label.sign-in-to-your-account",
                        defaultMessage: "Sign in to your account",
                      })}
                    />
                    <CardContent>
                      <Login
                        onLogin={onLogin}
                        openIDProviders={loaderData?.openIDConfigurations?.value?.Providers}
                        applicationConfiguration={
                          loaderData?.applicationConfiguration?.value?.Configuration
                        }
                      />
                    </CardContent>
                  </>
                ) : null}
              </UIErrorBoundary>
            </Card>
          </div>
        </PageCenter>
      </Page>
    </>
  );
}

Component.displayName = "Login";

export function ErrorBoundary() {
  return <ErrorFallback />;
}

export const loader = async () => {
  const preparedOpenIDConfigurationsService = getAvailableOpenIDConfigurationsLoaderQuery(
    queryClient,
    {},
  );
  const preparedGetApplicationConfigurationService = getApplicationConfigurationLoaderQuery(
    queryClient,
    {},
    ["root"],
    { staleTime: ONE_HOUR, cacheTime: ONE_HOUR },
  );

  const [openIDConfigurations, applicationConfiguration] = await Promise.all([
    preparedOpenIDConfigurationsService(),
    preparedGetApplicationConfigurationService(),
  ]);

  return {
    openIDConfigurations,
    applicationConfiguration,
  };
};

export type LoginLoaderData = InferLoaderOrActionReturnType<typeof loader>;

export type LoginActionData = {
  success: boolean;
  formErrors?: Record<string, string>;
  serviceError?: string;
  loginResponse?: EVA.Core.LoginResponse;
  chosenAuthenticatorName?: EVA.Core.TwoFactorAuthenticatorData["Name"];
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const { intent, ...formValues } = Object.fromEntries(await (await request.formData()).entries());
  if (intent === "credentialsLogin") {
    return loginByCredentials(queryClient, formValues);
  }

  if (intent === "2faConfirmation") {
    return confirm2FA(queryClient, formValues);
  }

  return null;
};
