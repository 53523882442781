import { FormattedMessage, useIntl } from "react-intl";

import { Button, Card, CardContent, CardHeader, TextField } from "@new-black/lyra";
import { Core } from "@springtree/eva-services-core";
import { Field, FieldProps, Form, FormikHelpers } from "formik";
import * as yup from "yup";

import { ButtonLink, useNavigate } from "~/components/routing";
import { ErrorFallback } from "~/components/shared/error-fallback";
import { LogoIcon } from "~/components/shared/header/logo";
import { Page } from "~/components/shared/page";
import PageCenter from "~/components/shared/page-center";
import { EVAFormik } from "~/components/suite-composite/eva-formik";
import { TitleHelmet } from "~/components/suite-composite/title-helmet";
import useCallService from "~/hooks/suite-react-hooks/use-call-service";
import routeDefinitions from "~/routes/route-definitions";

const useForgotPasswordSchema = () => {
  const intl = useIntl();

  const forgotPasswordSchema = yup.object().shape({
    EmailAddress: yup
      .string()
      .email(
        intl.formatMessage({
          id: "validation.email",
          defaultMessage: "The entered value is not a valid email address",
        }),
      )
      .required(
        intl.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required",
        }),
      ),
  });

  return forgotPasswordSchema;
};

type FormType = {
  EmailAddress?: string;
};

export function Component() {
  /**
   * Reference to i18n helper
   */
  const intl = useIntl();
  const forgotPasswordSchema = useForgotPasswordSchema();
  const navigate = useNavigate();

  const { callService: requestPasswordToken } = useCallService({
    service: Core.RequestPasswordResetToken,
    messages: {
      successMessage: intl.formatMessage({
        id: "auth.forget-password.success",
        defaultMessage: "Password reset email is succesfully sent",
      }),
    },
  });

  const onSubmit = async (values: FormType, formikHelpers: FormikHelpers<FormType>) => {
    if (values.EmailAddress) {
      await requestPasswordToken(
        {
          EmailAddress: values.EmailAddress,
          AsEmployee: true,
          Context: "admin",
        },
        { authenticationToken: undefined },
      );
      formikHelpers.setSubmitting(false);
      navigate(routeDefinitions.auth.login.path, { replace: true });
    }
  };

  return (
    <>
      <TitleHelmet
        title={intl.formatMessage({
          id: "page.forgot-password.title",
          defaultMessage: "Forgot password",
        })}
      />
      <Page>
        <PageCenter>
          <div className="w-[360px] max-w-full">
            <div className="flex justify-center pb-10 pt-6">
              <LogoIcon className="text-primary" width={121} height={46} />
            </div>
            <Card>
              <CardHeader
                title={intl.formatMessage({
                  id: "login.reset-your-password",
                  defaultMessage: "Reset your password",
                })}
                description={intl.formatMessage({
                  id: "login.reset-password-description",
                  defaultMessage:
                    "Please input the email address linked to your account, and we will send you a link to reset your password.",
                })}
              />
              <CardContent>
                <EVAFormik<FormType>
                  validationSchema={forgotPasswordSchema}
                  initialValues={{ EmailAddress: "" }}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Field name="EmailAddress">
                        {({ field, form }: FieldProps) => (
                          <TextField
                            type="email"
                            isRequired
                            label={intl.formatMessage({
                              id: "generic.label.email-address",
                              defaultMessage: "Email address",
                            })}
                            isDisabled={isSubmitting}
                            {...field}
                            onChange={(newValue) => {
                              form.setFieldValue("EmailAddress", newValue);
                            }}
                          />
                        )}
                      </Field>
                      <div className="mt-4 flex w-full flex-col gap-2">
                        <Button type="submit" fullWidth isLoading={isSubmitting}>
                          <FormattedMessage
                            id="auth.forget-password.button"
                            defaultMessage="Send password reset"
                          />
                        </Button>
                        <ButtonLink
                          to={routeDefinitions.auth.login.path}
                          fullWidth
                          variant="secondary"
                        >
                          <FormattedMessage
                            id="login.return-to-sign-in"
                            defaultMessage="Return to sign in"
                          />
                        </ButtonLink>
                      </div>
                    </Form>
                  )}
                </EVAFormik>
              </CardContent>
            </Card>
          </div>
        </PageCenter>
      </Page>
    </>
  );
}

Component.displayName = "ForgotPasswordPage";

export function ErrorBoundary() {
  return <ErrorFallback fullPage />;
}
