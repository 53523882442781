import { Navigate, RouteObject } from "react-router-dom";

import { NavigateWithParams } from "~/components/routing";
import { ErrorFallback } from "~/components/shared/error-fallback";
import { EModuleCode } from "~/features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "~/routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "~/routes/lazy-with-retry";
import ModuleChapterRedirecter from "~/routes/module-chapter-redirecter";
import routeDefinitions from "~/routes/route-definitions";

const { organizations } = routeDefinitions;

const organizationUnitDetailsBasicInformationRoutes: RouteObject = {
  path: organizations.organizations.details.basicInformation.path,
  lazy: lazyWithRetry(async () => {
    const [page] = await Promise.all([
      import("./organizations.organizations.$id.basic-information"),
      import("./organizations.organizations.$id.basic-information.add-organization-language"),
      import("./organizations.organizations.$id.basic-information.edit-advanced-information"),
      import("./organizations.organizations.$id.basic-information.edit-basic-information"),
      import("./organizations.organizations.$id.basic-information.edit-contact-information"),
    ]);
    return page;
  }),
  children: [
    {
      path: organizations.organizations.details.basicInformation.editBasicInformation.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.basic-information.edit-basic-information"),
      ),
    },
    {
      path: organizations.organizations.details.basicInformation.editContactInformation.path,
      lazy: lazyWithRetry(
        () =>
          import("./organizations.organizations.$id.basic-information.edit-contact-information"),
      ),
    },
    {
      path: organizations.organizations.details.basicInformation.editAdvancedInformation.path,
      lazy: lazyWithRetry(
        () =>
          import("./organizations.organizations.$id.basic-information.edit-advanced-information"),
      ),
    },
    {
      path: organizations.organizations.details.basicInformation.addOrganizationLanguage.path,
      lazy: lazyWithRetry(
        () =>
          import("./organizations.organizations.$id.basic-information.add-organization-language"),
      ),
    },
  ],
};

const organizationsDetailFinancialRoutes: RouteObject = {
  path: organizations.organizations.details.financial.path,
  lazy: lazyWithRetry(async () => {
    const [page] = await Promise.all([
      import("./organizations.organizations.$id.financial"),
      import("./organizations.organizations.$id.financial.add-currency"),
      import("./organizations.organizations.$id.financial.edit-currency.$currencyID"),
      import("./organizations.organizations.$id.financial.edit-financial-information"),
    ]);
    return page;
  }),
  children: [
    {
      path: organizations.organizations.details.financial.editFinancialInformation.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.financial.edit-financial-information"),
      ),
    },
    {
      path: organizations.organizations.details.financial.addCurrency.path,
      lazy: lazyWithRetry(() => import("./organizations.organizations.$id.financial.add-currency")),
    },
    {
      path: organizations.organizations.details.financial.editCurrency.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.financial.edit-currency.$currencyID"),
      ),
    },
  ],
};

const organizationsDetailLogisticsRoutes: RouteObject = {
  path: organizations.organizations.details.logistics.path,
  lazy: lazyWithRetry(async () => {
    const [page] = await Promise.all([
      import("./organizations.organizations.$id.logistics"),
      import("./organizations.organizations.$id.logistics.add-available-country"),
      import("./organizations.organizations.$id.logistics.add-shipping-method"),
    ]);
    return page;
  }),
  children: [
    {
      path: organizations.organizations.details.logistics.addAvailableCountry.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.logistics.add-available-country"),
      ),
    },
    {
      path: organizations.organizations.details.logistics.addShippingMethod.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.logistics.add-shipping-method"),
      ),
    },
  ],
};

const organizationsDetailOpeningHoursRoutes: RouteObject = {
  path: organizations.organizations.details.openingHours.path,
  lazy: lazyWithRetry(async () => {
    const [page] = await Promise.all([
      import("./organizations.organizations.$id.opening-hours"),
      import("./organizations.organizations.$id.opening-hours.add-special-opening-hours"),
      import("./organizations.organizations.$id.opening-hours.edit-regular-opening-hours"),
      import("./organizations.organizations.$id.opening-hours.edit-special-opening-hours"),
    ]);
    return page;
  }),
  children: [
    {
      path: organizations.organizations.details.openingHours.addSpecialOpeningHours.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.opening-hours.add-special-opening-hours"),
      ),
    },
    {
      path: organizations.organizations.details.openingHours.editRegularOpeningHours.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.opening-hours.edit-regular-opening-hours"),
      ),
    },
    {
      path: organizations.organizations.details.openingHours.editSpecialOpeningHours.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.opening-hours.edit-special-opening-hours"),
      ),
    },
  ],
};

const organizationsDetailCustomFieldsRoutes: RouteObject = {
  path: organizations.organizations.details.customFields.path,
  lazy: lazyWithRetry(() => import("./organizations.organizations.$id.custom-fields")),
};

const organizationsDetailFlightsRoutes: RouteObject = {
  path: organizations.organizations.details.flights.path,
  lazy: lazyWithRetry(async () => {
    const [page] = await Promise.all([
      import("./organizations.organizations.$id.flights"),
      import("./organizations.organizations.$id.flights.download-flights-template"),
      import("./organizations.organizations.$id.flights.import-flights"),
    ]);
    return page;
  }),
  children: [
    {
      path: organizations.organizations.details.flights.importFlights.path,
      lazy: lazyWithRetry(() => import("./organizations.organizations.$id.flights.import-flights")),
    },
    {
      path: organizations.organizations.details.flights.downloadTemplate.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.flights.download-flights-template"),
      ),
    },
  ],
};

const organizationsDetailFiscalSettingsRoutes: RouteObject = {
  path: organizations.organizations.details.fiscalSettings.path,
  lazy: lazyWithRetry(async () => {
    const [page] = await Promise.all([
      import("./organizations.organizations.$id.fiscal-settings"),
      import("./organizations.organizations.$id.fiscal-settings.$key.edit"),
    ]);
    return page;
  }),
  children: [
    {
      path: organizations.organizations.details.fiscalSettings.editFiscalSetting.path,
      lazy: lazyWithRetry(
        () => import("./organizations.organizations.$id.fiscal-settings.$key.edit"),
      ),
    },
  ],
};

// Parent is root /
export const organizationsRoutes: RouteObject[] = [
  {
    path: organizations.module.path,
    lazy: lazyWithRetry(() => import("./organizations")),
    children: [
      { index: true, element: <ModuleChapterRedirecter moduleCode={EModuleCode.ORGANIZATIONS} /> },
      {
        path: organizations.organizations.overview.path,
        lazy: lazyWithRetry(() => import("./organizations.organizations._index")),
      },
      {
        path: organizations.organizations.create.path,
        lazy: lazyWithRetry(() => import("./organizations.organizations.new")),
      },
      {
        path: organizations.organizations.details.path,
        children: [
          {
            path: organizations.organizations.details.path,
            lazy: lazyWithRetry(() => import("./organizations.organizations.$id")),
            children: [
              {
                index: true,
                element: <Navigate to="./basic-information" replace />,
                errorElement: <ErrorFallback />,
              },
              organizationUnitDetailsBasicInformationRoutes,
              organizationsDetailFinancialRoutes,
              organizationsDetailLogisticsRoutes,
              organizationsDetailOpeningHoursRoutes,
              {
                path: organizations.organizations.details.sets.path,
                lazy: lazyWithRetry(() => import("./organizations.organizations.$id.sets")),
              },
              {
                path: organizations.organizations.details.organizationUnitStructure.path,
                lazy: lazyWithRetry(
                  () => import("./organizations.organizations.$id.organization-unit-structure"),
                ),
              },
              organizationsDetailCustomFieldsRoutes,
              organizationsDetailFlightsRoutes,
              organizationsDetailFiscalSettingsRoutes,
            ],
          },
        ],
      },
      {
        path: organizations.organizationsSets.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./organizations.organization-sets"),
            import("./organizations.organization-sets.create"),
            import("./organizations.organization-sets.$id"),
          ]);
          return page;
        }),
      },
      {
        path: organizations.organizationsSets.overview.createSet.path,
        lazy: lazyWithRetry(() => import("./organizations.organization-sets.create")),
      },
      {
        path: organizations.organizationsSets.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./organizations.organization-sets.$id"),
            import("./organizations.organization-sets.$id.update-set"),
          ]);
          return page;
        }),
        children: [
          {
            path: organizations.organizationsSets.details.updateSetModal.path,
            lazy: lazyWithRetry(() => import("./organizations.organization-sets.$id.update-set")),
          },
          {
            path: organizations.organizationsSets.details.addCountryFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.add-country-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.addTypeFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.add-type-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.addLanguageFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.add-language-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.editCountryFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.edit-country-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.editLanguageFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.edit-language-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.editTypeFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.edit-type-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.deleteCountryFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.delete-country-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.deleteLanguageFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.delete-language-filter"),
            ),
          },
          {
            path: organizations.organizationsSets.details.deleteTypeFilter.path,
            lazy: lazyWithRetry(
              () => import("./organizations.organization-sets.$id.delete-type-filter"),
            ),
          },
        ],
      },

      {
        path: organizations.openingHours.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./organizations.opening-hours"),
            import("./organizations.opening-hours.templates"),
            import("./organizations.opening-hours.types"),
            import("./organizations.opening-hours.templates.duplicate.$id"),
          ]);
          return page;
        }),
        children: [
          {
            path: organizations.openingHours.templates.path,
            lazy: lazyWithRetry(() => import("./organizations.opening-hours.templates")),
            children: [
              {
                path: organizations.openingHours.templates.duplicate.path,
                lazy: lazyWithRetry(
                  () => import("./organizations.opening-hours.templates.duplicate.$id"),
                ),
              },
            ],
          },

          {
            path: organizations.openingHours.types.path,
            lazy: lazyWithRetry(() => import("./organizations.opening-hours.types")),
            children: [
              {
                path: organizations.openingHours.types.edit.path,
                lazy: lazyWithRetry(() => import("./organizations.opening-hours-types.$id.edit")),
              },
              {
                path: organizations.openingHours.types.delete.path,
                lazy: lazyWithRetry(() => import("./organizations.opening-hours.types.$id.delete")),
              },
              {
                path: organizations.openingHours.types.create.path,
                lazy: lazyWithRetry(() => import("./organizations.opening-hours.types.new")),
              },
            ],
          },
        ],
      },
      {
        path: organizations.openingHours.templates.create.path,
        lazy: lazyWithRetry(() => import("./organizations.opening-hours.templates.create")),
      },
      {
        path: organizations.openingHours.templates.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./organizations.opening-hours.templates.$id"),
            import("./organizations.opening-hours.templates.$id.edit-general-information"),
            import("./organizations.opening-hours.templates.$id.attach-ou-sets"),
            import("./organizations.opening-hours.templates.$id.detach-ou-set.$ouSetId"),
            import(
              "./organizations.opening-hours.templates.$id.edit-regular-opening-hours.$dayIndex"
            ),
            import("./organizations.opening-hours.templates.$id.edit-special-opening-hours.$date"),
            import("./organizations.opening-hours.templates.$id.add-special-opening-hours"),
            import(
              "./organizations.opening-hours.templates.$id.delete-special-opening-hours.$date"
            ),
            import("./organizations.opening-hours.templates.$id.upload-special-opening-hours"),
          ]);
          return page;
        }),
        children: [
          {
            path: organizations.openingHours.templates.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./organizations.opening-hours.templates.$id.edit-general-information"),
            ),
          },
          {
            path: organizations.openingHours.templates.details.attachOrganizationUnitSets.path,
            lazy: lazyWithRetry(
              () => import("./organizations.opening-hours.templates.$id.attach-ou-sets"),
            ),
          },
          {
            path: organizations.openingHours.templates.details.detachOrganizationUnitSet.path,
            lazy: lazyWithRetry(
              () => import("./organizations.opening-hours.templates.$id.detach-ou-set.$ouSetId"),
            ),
          },
          {
            path: organizations.openingHours.templates.details.editRegularOpeningHours.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./organizations.opening-hours.templates.$id.edit-regular-opening-hours.$dayIndex"
                ),
            ),
          },
          {
            path: organizations.openingHours.templates.details.editSpecialOpeningHours.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./organizations.opening-hours.templates.$id.edit-special-opening-hours.$date"
                ),
            ),
          },
          {
            path: organizations.openingHours.templates.details.addSpecialOpeningHours.path,
            lazy: lazyWithRetry(
              () => import("./organizations.opening-hours.templates.$id.add-special-opening-hours"),
            ),
          },
          {
            path: organizations.openingHours.templates.details.deleteSpecialOpeningHours.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./organizations.opening-hours.templates.$id.delete-special-opening-hours.$date"
                ),
            ),
          },
          {
            path: organizations.openingHours.templates.details.uploadSpecialOpeningHours.path,
            lazy: lazyWithRetry(
              () =>
                import("./organizations.opening-hours.templates.$id.upload-special-opening-hours"),
            ),
          },
        ],
      },
      {
        path: organizations.companies.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./organizations.companies._index")),
          },
          {
            path: organizations.companies.new.path,
            lazy: lazyWithRetry(() => import("./organizations.companies.new")),
          },
          {
            path: organizations.companies.details.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./organizations.companies.$id"),
                import("./organizations.companies.$id.information"),
                import("./organizations.companies.$id.organization-units"),
                import("./organizations.companies.$id.auditing-fields"),
                import("./organizations.companies.$id.countries"),
              ]);
              return page;
            }),
            children: [
              {
                index: true,
                element: <Navigate to="./information" replace />,
                errorElement: <ErrorFallback />,
              },
              {
                path: organizations.companies.details.information.path,
                lazy: lazyWithRetry(() => import("./organizations.companies.$id.information")),
                children: [
                  {
                    path: organizations.companies.details.information.visitorsAddress.path,
                    lazy: lazyWithRetry(
                      () => import("./organizations.companies.$id.information.visitors-address"),
                    ),
                  },
                ],
              },
              {
                path: organizations.companies.details.organizationUnits.path,
                lazy: lazyWithRetry(
                  () => import("./organizations.companies.$id.organization-units"),
                ),
              },
              {
                path: organizations.companies.details.auditingFields.path,
                lazy: lazyWithRetry(() => import("./organizations.companies.$id.auditing-fields")),
              },
              {
                path: organizations.companies.details.countries.path,
                lazy: lazyWithRetry(() => import("./organizations.companies.$id.countries")),
              },
            ],
          },
        ],
      },
      {
        path: organizations.visibilityGroups.overview.path,
        children: [
          {
            path: organizations.visibilityGroups.overview.path,
            lazy: lazyWithRetry(() => import("./organizations.visibility-groups._index")),
          },
          {
            path: organizations.visibilityGroups.new.path,
            lazy: lazyWithRetry(() => import("./organizations.visibility-groups.new")),
          },
          {
            path: organizations.visibilityGroups.details.path,
            lazy: lazyWithRetry(() => import("./organizations.visibility-groups.$id")),
          },
        ],
      },
      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
      {
        path: ":id",
        element: <NavigateWithParams path={organizations.organizations.details.path} />,
      },
    ],
  },
];
