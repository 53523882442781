import { RouteObject } from "react-router-dom";

import { OverviewSearchParamsProvider } from "./pim.product-sets";

import { ErrorFallback } from "~/components/shared/error-fallback";
import { EModuleCode } from "~/features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "~/routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "~/routes/lazy-with-retry";
import ModuleChapterRedirecter from "~/routes/module-chapter-redirecter";
import pim from "~/routes/route-definitions/pim";

// Parent is root /
export const pimRoutes: RouteObject[] = [
  {
    path: pim.module.path,
    lazy: lazyWithRetry(() => import("./pim")),
    children: [
      { index: true, element: <ModuleChapterRedirecter moduleCode={EModuleCode.PIM} /> },

      {
        path: pim.productBundles.overview.path,
        lazy: lazyWithRetry(() => import("./pim.product-bundles")),
      },

      {
        path: pim.productBundles.create.path,
        lazy: lazyWithRetry(() => import("./pim.product-bundles.new")),
      },

      {
        path: pim.productBundles.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./pim.product-bundles.$id"),
            import("./pim.product-bundles.$id.edit"),
            import("./pim.product-bundles.$id.delete"),
            import("./pim.product-bundles.$id.reorganize.$mediaType"),
            import("./pim.product-bundles.$id.upload.$mediaType"),
            import("./pim.product-bundles.$id.delete.$mediaType.$blobId"),
            import("./pim.product-bundles.$id.set-primary.$blobId"),
            import("./pim.product-bundles.$id.prices.new"),
            import("./pim.product-bundles.$id.prices.$priceListID"),
            import("./pim.product-bundles.$id.product-sets.new"),
            import("./pim.product-bundles.$id.product-sets.$lineId.edit-general-info"),
            import("./pim.product-bundles.$id.product-sets.$lineId.add-product"),
            import("./pim.product-bundles.$id.product-sets.$lineId.$productId.set-as-default"),
            import("./pim.product-bundles.$id.product-sets.$lineId.$productId.remove-product"),
            import("./pim.product-bundles.$id.product-sets.$lineId.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: pim.productBundles.details.edit.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.edit")),
          },
          {
            path: pim.productBundles.details.delete.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.delete")),
          },
          {
            path: pim.productBundles.details.reorganizeMedia.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.reorganize.$mediaType")),
          },
          {
            path: pim.productBundles.details.uploadMedia.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.upload.$mediaType")),
          },
          {
            path: pim.productBundles.details.deleteMedia.path,
            lazy: lazyWithRetry(
              () => import("./pim.product-bundles.$id.delete.$mediaType.$blobId"),
            ),
          },
          {
            path: pim.productBundles.details.setPrimary.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.set-primary.$blobId")),
          },

          {
            path: pim.productBundles.details.prices.create.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.prices.new")),
          },
          {
            path: pim.productBundles.details.prices.edit.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.prices.$priceListID")),
          },

          {
            path: pim.productBundles.details.productSets.create.path,
            lazy: lazyWithRetry(() => import("./pim.product-bundles.$id.product-sets.new")),
          },

          {
            path: pim.productBundles.details.productSets.editGeneralInfo.path,
            lazy: lazyWithRetry(
              () => import("./pim.product-bundles.$id.product-sets.$lineId.edit-general-info"),
            ),
          },
          {
            path: pim.productBundles.details.productSets.addProduct.path,
            lazy: lazyWithRetry(
              () => import("./pim.product-bundles.$id.product-sets.$lineId.add-product"),
            ),
          },
          {
            path: pim.productBundles.details.productSets.selectProductAsDefault.path,
            lazy: lazyWithRetry(
              () =>
                import("./pim.product-bundles.$id.product-sets.$lineId.$productId.set-as-default"),
            ),
          },
          {
            path: pim.productBundles.details.productSets.removeProduct.path,
            lazy: lazyWithRetry(
              () =>
                import("./pim.product-bundles.$id.product-sets.$lineId.$productId.remove-product"),
            ),
          },
          {
            path: pim.productBundles.details.productSets.delete.path,
            lazy: lazyWithRetry(
              () => import("./pim.product-bundles.$id.product-sets.$lineId.delete"),
            ),
          },
          {
            path: pim.productBundles.details.productSets.combinations.path,
            lazy: lazyWithRetry(
              () =>
                import("./pim.product-bundles.$id.product-sets.$lineId.$productId.combinations"),
            ),
          },
        ],
      },

      {
        path: pim.productRelations.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./pim.product-relations._index")),
          },
          {
            path: pim.productRelations.create.path,
            lazy: lazyWithRetry(() => import("./pim.product-relations.new")),
          },
          {
            path: pim.productRelations.details.path,
            lazy: lazyWithRetry(() => import("./pim.product-relations.$id")),
          },
        ],
      },
      {
        path: pim.productSets.overview.path,
        element: <OverviewSearchParamsProvider />,
        errorElement: <ErrorFallback fullPage />,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./pim.product-sets._index"),
                import("./pim.product-sets.new"),
                import("./pim.product-sets.$id"),
                import("./pim.product-sets.$id.edit-general-info"),
                import("./pim.product-sets.$id.edit-ecom-filters"),
              ]);

              return page;
            }),
          },
          {
            path: pim.productSets.create.path,
            lazy: lazyWithRetry(() => import("./pim.product-sets.new")),
          },
          {
            path: pim.productSets.details.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./pim.product-sets.$id"),
                import("./pim.product-sets.$id.edit-general-info"),
                import("./pim.product-sets.$id.edit-ecom-filters"),
              ]);
              return page;
            }),
            children: [
              {
                path: pim.productSets.details.editGeneralInfo.path,
                lazy: lazyWithRetry(() => import("./pim.product-sets.$id.edit-general-info")),
              },
              {
                path: pim.productSets.details.editEcomFilters.path,
                lazy: lazyWithRetry(() => import("./pim.product-sets.$id.edit-ecom-filters")),
              },
            ],
          },
        ],
      },

      {
        path: pim.unitsOfMeasure.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./pim.units-of-measure")),
          },
        ],
      },

      {
        path: pim.unitsOfMeasure.create.path,
        lazy: lazyWithRetry(() => import("./pim.units-of-measure.new")),
      },

      {
        path: pim.unitsOfMeasure.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./pim.units-of-measure.$id"),
            import("./pim.units-of-measure.$id.add-product"),
            import("./pim.units-of-measure.$id.delete"),
            import("./pim.units-of-measure.$id.delete-product.$productUomId"),
            import("./pim.units-of-measure.$id.edit"),
          ]);
          return page;
        }),
        children: [
          {
            path: pim.unitsOfMeasure.details.addProduct.path,
            lazy: lazyWithRetry(() => import("./pim.units-of-measure.$id.add-product")),
          },
          {
            path: pim.unitsOfMeasure.details.deleteUnitOfMeasure.path,
            lazy: lazyWithRetry(() => import("./pim.units-of-measure.$id.delete")),
          },
          {
            path: pim.unitsOfMeasure.details.deleteProDuctFromUnitOfMeasure.path,
            lazy: lazyWithRetry(
              () => import("./pim.units-of-measure.$id.delete-product.$productUomId"),
            ),
          },
          {
            path: pim.unitsOfMeasure.details.edit.path,
            lazy: lazyWithRetry(() => import("./pim.units-of-measure.$id.edit")),
          },
        ],
      },

      {
        path: pim.products.productsOverview.details.path,
        lazy: lazyWithRetry(() => import("./pim.products.products-overview.$id")),
        children: [
          {
            path: pim.products.productsOverview.details.contentPreview.path,
            lazy: lazyWithRetry(
              () => import("./pim.products.products-overview.$id.content-preview"),
            ),
          },
        ],
      },
      {
        path: pim.products.assortments.create.path,
        lazy: lazyWithRetry(() => import("./pim.products.assortments.new")),
      },
      {
        path: pim.products.assortments.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./pim.products.assortments.$id"),
            import("./pim.products.assortments.$id.edit"),
            import("./pim.products.assortments.$id.attach-ous"),
            import("./pim.products.assortments.$id.products.$productId.delete"),
            import("./pim.products.assortments.$id.products.$productId.settings"),
            import("./pim.products.assortments.$id.products.add"),
            import("./pim.products.assortments.$id.products.download"),
            import("./pim.products.assortments.$id.products.upload"),
          ]);
          return page;
        }),
        children: [
          {
            path: pim.products.assortments.details.edit.path,
            lazy: lazyWithRetry(() => import("./pim.products.assortments.$id.edit")),
          },
          {
            path: pim.products.assortments.details.attachOus.path,
            lazy: lazyWithRetry(() => import("./pim.products.assortments.$id.attach-ous")),
          },
          {
            path: pim.products.assortments.details.editProductSettings.path,
            lazy: lazyWithRetry(
              () => import("./pim.products.assortments.$id.products.$productId.settings"),
            ),
          },
          {
            path: pim.products.assortments.details.deleteProduct.path,
            lazy: lazyWithRetry(
              () => import("./pim.products.assortments.$id.products.$productId.delete"),
            ),
          },
          {
            path: pim.products.assortments.details.downloadProducts.path,
            lazy: lazyWithRetry(() => import("./pim.products.assortments.$id.products.download")),
          },
          {
            path: pim.products.assortments.details.uploadProducts.path,
            lazy: lazyWithRetry(() => import("./pim.products.assortments.$id.products.upload")),
          },
          {
            path: pim.products.assortments.details.addProduct.path,
            lazy: lazyWithRetry(() => import("./pim.products.assortments.$id.products.add")),
          },
        ],
      },

      {
        path: pim.products.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./pim.products._index"),
            import("./pim.products.products-overview._index"),
            import("./pim.products.product-properties._index"),
            import("./pim.products.product-properties.new"),
            import("./pim.products.assortments._index"),
          ]);

          return page;
        }),
        children: [
          {
            path: pim.products.productsOverview.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./pim.products.products-overview._index"),
                import("./pim.products.products-overview.download-product-content"),
              ]);

              return page;
            }),
            children: [
              {
                path: pim.products.productsOverview.overview.downloadProductContent.path,
                lazy: lazyWithRetry(
                  () => import("./pim.products.products-overview.download-product-content"),
                ),
              },

              {
                path: pim.products.productsOverview.overview.uploadProductInformation.path,
                lazy: lazyWithRetry(
                  () => import("./pim.products.products-overview.upload-product-information"),
                ),
              },

              {
                path: pim.products.productsOverview.overview.uploadProductContent.path,
                lazy: lazyWithRetry(
                  () => import("./pim.products.products-overview.upload-product-content"),
                ),
              },
            ],
          },

          {
            path: pim.products.productProperties.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./pim.products.product-properties._index"),
                import("./pim.products.product-properties.new"),
              ]);

              return page;
            }),

            children: [
              {
                path: pim.products.productProperties.overview.create.path,
                lazy: lazyWithRetry(() => import("./pim.products.product-properties.new")),
              },
            ],
          },
          {
            path: pim.products.assortments.overview.path,
            lazy: lazyWithRetry(() => import("./pim.products.assortments._index")),
          },
        ],
      },

      {
        path: pim.plugin.path,
        lazy: lazyWithRetry(() => import("./pim.plugin._index")),
      },
      {
        path: pim.productRequirements.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./pim.product-requirements._index"),
            import("./pim.product-requirements.new"),
          ]);
          return page;
        }),

        children: [
          {
            path: pim.productRequirements.overview.new.path,
            lazy: lazyWithRetry(() => import("./pim.product-requirements.new")),
          },
        ],
      },

      {
        path: pim.productRequirements.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./pim.product-requirements.$productId"),
            import("./pim.product-requirements.$productId.create"),
            import("./pim.product-requirements.$productId.delete"),
            import("./pim.product-requirements.$productId.edit"),
          ]);
          return page;
        }),
        children: [
          {
            path: pim.productRequirements.details.create.path,
            lazy: lazyWithRetry(() => import("./pim.product-requirements.$productId.create")),
          },
          {
            path: pim.productRequirements.details.edit.path,
            lazy: lazyWithRetry(() => import("./pim.product-requirements.$productId.edit")),
          },
          {
            path: pim.productRequirements.details.delete.path,
            lazy: lazyWithRetry(() => import("./pim.product-requirements.$productId.delete")),
          },
        ],
      },

      /**
       * EVA Intent Handler
       */
      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
    ],
  },
];
