import { RouteObject } from "react-router-dom";

import lazyWithRetry from "~/routes/lazy-with-retry";
import routeDefinitions from "~/routes/route-definitions";

export { AddEditCustomFieldForm } from "./route";

export const addEditCustomFieldsResourceRoutes: RouteObject[] = [
  {
    path: routeDefinitions.resource.addEditCustomField.add.path,
    lazy: lazyWithRetry(() => import("./route")),
  },
  {
    path: routeDefinitions.resource.addEditCustomField.edit.path,
  },
];
