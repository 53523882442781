import { Routes } from "./routes";

import { PromptRoutingProvider } from "~/components/routing";
import TranslationProvider from "~/components/suite-composite/translation-provider";

export const Router = () => {
  return (
    <div>
      <TranslationProvider>
        <PromptRoutingProvider>
          <div>
            <Routes />
          </div>
        </PromptRoutingProvider>
      </TranslationProvider>
    </div>
  );
};
