import { FormattedMessage, useIntl } from "react-intl";

import { Card } from "@new-black/lyra";

import { Header } from "~/components/shared/header";
import { Page } from "~/components/shared/page";
import PageCenter from "~/components/shared/page-center";
import { TitleHelmet } from "~/components/suite-composite/title-helmet";
import Text from "~/components/suite-ui/text";

/**
 * Page to display when user doesn't have access to this admin module
 */
export function Component() {
  /**
   * Reference to i18n helper
   */
  const intl = useIntl();

  return (
    <>
      <TitleHelmet
        title={intl.formatMessage({ id: "page.401.title", defaultMessage: "Unauthorized" })}
      />
      <Page>
        <Header />
        <PageCenter>
          <div className="w-[400px] max-w-full">
            <Card>
              <div className="p-12">
                <Text variant="h1">
                  <FormattedMessage id="page.401.title" defaultMessage="Unauthorized" />
                </Text>
                <Text variant="body1">
                  <FormattedMessage
                    id="page.401.description"
                    defaultMessage="You don't have access to this module"
                  />
                </Text>
              </div>
            </Card>
          </div>
        </PageCenter>
      </Page>
    </>
  );
}

Component.displayName = "UnauthorizedPage";
