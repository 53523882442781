import { Navigate, RouteObject } from "react-router-dom";

import { adminSuiteConfig } from "../../../admin-suite.config";

import { NavigateWithParams } from "~/components/routing";
import { ErrorFallback } from "~/components/shared/error-fallback";
import { EModuleCode } from "~/features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "~/routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "~/routes/lazy-with-retry";
import ModuleChapterRedirecter from "~/routes/module-chapter-redirecter";
import routeDefinitions from "~/routes/route-definitions";

const { financials } = routeDefinitions;

const invoicesChapter = financials.invoices;
const priceListsChapter = financials.priceLists;
const currenciesChapter = financials.currencies;
const exportLogsChapter = financials.exportLogs;
const financialPeriods = financials.financialPeriods;
const taxManagementChapter = financials.taxManagement;
const incomeAndExpensesChapter = financials.incomeAndExpenses;
const giftcardsChapter = financials.giftcards;
const settlementsChapter = financials.settlements;
const generalLedgersAndAccountsChapter = financials.generalLedgersAndAccounts;

const cookbookRecipesChapter = financials.cookbookRecipes;
const cookbookRecipesChapterV1 = financials.cookbookRecipesV1;
const cookbookEventsChapter = financials.cookbookEvents;

const getCookbookRecipesV2Routes = () => {
  let routes: RouteObject[] = [];

  if (adminSuiteConfig.cookbookV2) {
    routes = [
      {
        path: cookbookRecipesChapter.overview.path,
        lazy: lazyWithRetry(() => import("./financials.cookbook-recipes")),
      },
      {
        path: cookbookRecipesChapter.create.path,
        lazy: lazyWithRetry(() => import("./financials.cookbook-recipes.new")),
      },
      {
        path: cookbookRecipesChapter.details.path,
        lazy: lazyWithRetry(() => import("./financials.cookbook-recipes.$id")),
        children: [
          {
            path: cookbookRecipesChapter.details.recipe.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./financials.cookbook-recipes.$id.edit-general-information"),
            ),
          },
          {
            path: cookbookRecipesChapter.details.recipe.editRecipe.path,
            lazy: lazyWithRetry(() => import("./financials.cookbook-recipes.$id.edit-recipe")),
          },
        ],
      },
    ];
  }

  return routes;
};

const getCookbookRecipesV1Routes = () => {
  let routes: RouteObject[] = [];

  if (adminSuiteConfig.cookbookV1) {
    routes = [
      {
        path: cookbookRecipesChapterV1.overview.path,
        lazy: lazyWithRetry(() => import("./financials.cookbook-recipes-v1")),
      },
      {
        path: cookbookRecipesChapterV1.create.path,
        lazy: lazyWithRetry(() => import("./financials.cookbook-recipes-v1.new")),
      },
      {
        path: cookbookRecipesChapterV1.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./financials.cookbook-recipes-v1.$id"),
            import("./financials.cookbook-recipes-v1.$id.recipe"),
            import("./financials.cookbook-recipes-v1.$id.recipe.edit-general-information"),
            import("./financials.cookbook-recipes-v1.$id.recipe.edit-recipe"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            element: <NavigateWithParams path="./recipe" replace />,
          },
          {
            path: cookbookRecipesChapterV1.details.recipe.path,
            lazy: lazyWithRetry(() => import("./financials.cookbook-recipes-v1.$id.recipe")),

            children: [
              {
                path: cookbookRecipesChapterV1.details.recipe.editGeneralInformation.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./financials.cookbook-recipes-v1.$id.recipe.edit-general-information"),
                ),
              },
              {
                path: cookbookRecipesChapterV1.details.recipe.editRecipe.path,
                lazy: lazyWithRetry(
                  () => import("./financials.cookbook-recipes-v1.$id.recipe.edit-recipe"),
                ),
              },
            ],
          },
          {
            path: cookbookRecipesChapterV1.details.preview.path,
            lazy: lazyWithRetry(() => import("./financials.cookbook-recipes-v1.$id.preview")),

            children: [
              {
                path: cookbookRecipesChapterV1.details.preview.results.path,
                lazy: lazyWithRetry(
                  () => import("./financials.cookbook-recipes-v1.$id.preview.results"),
                ),
              },
            ],
          },
        ],
      },
    ];
  }

  return routes;
};

// Parent is root /
export const financialsRoutes: RouteObject[] = [
  {
    path: financials.module.path,
    lazy: lazyWithRetry(() => import("./financials")),
    children: [
      {
        index: true,
        element: <ModuleChapterRedirecter moduleCode={EModuleCode.FINANCIALS} />,
      },
      {
        path: financials.invoices.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./financials.invoices._index")),
          },
          {
            path: invoicesChapter.details.view.path,
            lazy: lazyWithRetry(() => import("./financials.invoices.view.$id")),
          },
          {
            path: invoicesChapter.details.edit.path,
            lazy: lazyWithRetry(() => import("./financials.invoices.edit.$id")),
          },
          {
            path: invoicesChapter.details.new.path,
            lazy: lazyWithRetry(() => import("./financials.invoices.new")),
          },
        ],
      },
      {
        path: financials.payment.wrapper.path,
        lazy: lazyWithRetry(() => import("./financials.payment")),
        children: [
          {
            path: financials.payment.paymentMethods.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./financials.payment.payment-methods"),
                import("./financials.payment.payment-methods.add"),
                import("./financials.payment.payment-methods.delete"),
                import("./financials.payment.payment-methods.$id.edit"),
              ]);
              return page;
            }),
            children: [
              {
                path: financials.payment.paymentMethods.overview.add.path,
                lazy: lazyWithRetry(() => import("./financials.payment.payment-methods.add")),
              },
              {
                path: financials.payment.paymentMethods.overview.delete.path,
                lazy: lazyWithRetry(() => import("./financials.payment.payment-methods.delete")),
              },
              {
                path: financials.payment.paymentMethods.overview.edit.path,
                lazy: lazyWithRetry(() => import("./financials.payment.payment-methods.$id.edit")),
              },
            ],
          },
          {
            path: financials.payment.paymentTypes.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./financials.payment.payment-types._index"),
                import("./financials.payment.payment-types.add"),
              ]);
              return page;
            }),
          },
          {
            path: financials.payment.paymentSettings.overview.path,
            lazy: lazyWithRetry(() => import("./financials.payment.payment-settings._index")),
          },
        ],
      },

      {
        path: financials.payment.paymentTypes.add.path,
        lazy: lazyWithRetry(() => import("./financials.payment.payment-types.add")),
      },

      {
        path: financials.payment.paymentTypes.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./financials.payment.payment-types.$id"),
            import("./financials.payment.payment-types.$id.delete"),
            import("./financials.payment.payment-types.$id.edit-general-info"),
            import("./financials.payment.payment-types.$id.custom-fields.add"),
            import(
              "./financials.payment.payment-types.$id.custom-fields.$customFieldId.edit-general-information"
            ),
            import("./financials.payment.payment-types.$id.custom-fields.$customFieldId.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: financials.payment.paymentTypes.details.editGeneralInfo.path,
            lazy: lazyWithRetry(
              () => import("./financials.payment.payment-types.$id.edit-general-info"),
            ),
          },

          {
            path: financials.payment.paymentTypes.details.delete.path,
            lazy: lazyWithRetry(() => import("./financials.payment.payment-types.$id.delete")),
          },

          {
            path: financials.payment.paymentTypes.details.editCustomFieldGeneralInfo.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./financials.payment.payment-types.$id.custom-fields.$customFieldId.edit-general-information"
                ),
            ),
          },

          {
            path: financials.payment.paymentTypes.details.deleteCustomField.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./financials.payment.payment-types.$id.custom-fields.$customFieldId.delete"
                ),
            ),
          },

          {
            path: financials.payment.paymentTypes.details.addCustomField.path,
            lazy: lazyWithRetry(
              () => import("./financials.payment.payment-types.$id.custom-fields.add"),
            ),
          },
        ],
      },

      {
        path: "payment/payment-methods/:typeId/payment-types/:id",
        errorElement: <ErrorFallback fullPage />,
        element: <NavigateWithParams path={financials.payment.paymentTypes.details.path} replace />,
      },

      {
        path: "payment-methods",
        errorElement: <ErrorFallback fullPage />,
        children: [
          {
            index: true,
            element: (
              <NavigateWithParams path={financials.payment.paymentMethods.overview.path} replace />
            ),
          },
          {
            path: "payment-methods/:id/payment-types/:typeId",
            element: (
              <NavigateWithParams path={financials.payment.paymentTypes.details.path} replace />
            ),
          },
        ],
      },

      {
        path: "payment-settings",
        element: <Navigate to={financials.payment.paymentSettings.overview.path} replace />,
      },

      {
        path: priceListsChapter.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(
              () => import("./financials.price-lists._index/financials.price-lists._index"),
            ),
          },
          {
            path: priceListsChapter.create.path,
            lazy: lazyWithRetry(() => import("./financials.price-lists.new")),
          },
          {
            path: priceListsChapter.details.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./financials.price-lists.$id"),
                import("./financials.price-lists.$id.delete"),
                import("./financials.price-lists.$id.adjustments.add"),
                import("./financials.price-lists.$id.adjustments.$adjustmentId.edit"),
                import("./financials.price-lists.$id.adjustments.$adjustmentId.products.upload"),
                import("./financials.price-lists.$id.edit-general-info"),
                import("./financials.price-lists.$id.adjustments.$adjustmentId.add-product"),
                import("./financials.price-lists.$id.organization-units.add"),
                import(
                  "./financials.price-lists.$id.adjustements.$adjustmentId.products.$manualAdjustmentProductId.edit"
                ),
                import(
                  "./financials.price-lists.$id.organization-units.$priceListOrganizationUnitId.delete"
                ),
                import(
                  "./financials.price-lists.$id.organization-units.$priceListOrganizationUnitId.edit"
                ),
                import("./financials.price-lists.$id.adjustments.$adjustmentId.products.upload"),
              ]);
              return page;
            }),

            children: [
              {
                path: priceListsChapter.details.editGeneralDetails.path,
                lazy: lazyWithRetry(() => import("./financials.price-lists.$id.edit-general-info")),
              },
              {
                path: priceListsChapter.details.deleteOrganizationUnit.path,
                lazy: () =>
                  import(
                    "./financials.price-lists.$id.organization-units.$priceListOrganizationUnitId.delete"
                  ),
              },
              {
                path: priceListsChapter.details.addOrganizationUnit.path,
                lazy: lazyWithRetry(
                  () => import("./financials.price-lists.$id.organization-units.add"),
                ),
              },
              {
                path: priceListsChapter.details.editOrganizationUnit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.price-lists.$id.organization-units.$priceListOrganizationUnitId.edit"
                    ),
                ),
              },
              {
                path: priceListsChapter.details.addAdjustment.path,
                lazy: lazyWithRetry(() => import("./financials.price-lists.$id.adjustments.add")),
              },
              {
                path: priceListsChapter.details.editAdjustment.path,
                lazy: lazyWithRetry(
                  () => import("./financials.price-lists.$id.adjustments.$adjustmentId.edit"),
                ),
              },
              {
                path: priceListsChapter.details.addManualAdjustmentProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./financials.price-lists.$id.adjustments.$adjustmentId.add-product"),
                ),
              },
              {
                path: priceListsChapter.details.editManualAdjustmentProduct.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.price-lists.$id.adjustements.$adjustmentId.products.$manualAdjustmentProductId.edit"
                    ),
                ),
              },
              {
                path: priceListsChapter.details.uploadManualAdjustmentProducts.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.price-lists.$id.adjustments.$adjustmentId.products.upload"
                    ),
                ),
              },
              {
                path: priceListsChapter.details.delete.path,
                lazy: lazyWithRetry(() => import("./financials.price-lists.$id.delete")),
              },
            ],
          },
        ],
      },
      {
        path: currenciesChapter.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./financials.currencies")),
          },
          {
            path: currenciesChapter.details.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./financials.currencies.$id"),
                import("./financials.currencies.$id.cash-handlers.$cashHandlerId"),
                import("./financials.currencies.$id.cash-handlers.new"),
              ]);
              return page;
            }),
            children: [
              {
                path: currenciesChapter.details.addCashHandler.path,
                lazy: lazyWithRetry(() => import("./financials.currencies.$id.cash-handlers.new")),
              },
              {
                path: currenciesChapter.details.manageCashHandler.path,
                lazy: lazyWithRetry(
                  () => import("./financials.currencies.$id.cash-handlers.$cashHandlerId"),
                ),
              },
            ],
          },
        ],
      },
      {
        path: exportLogsChapter.overview.path,
        lazy: lazyWithRetry(() => import("./financials.export-logs")),
        children: [
          {
            index: true,
            element: <NavigateWithParams path="./financial-period-exports" replace />,
          },
          {
            index: true,
            path: exportLogsChapter.overview.financialPeriodExports.path,
            lazy: lazyWithRetry(() => import("./financials.export-logs.financial-period-exports")),
          },
          {
            path: exportLogsChapter.overview.invoiceExports.path,
            lazy: lazyWithRetry(() => import("./financials.export-logs.invoice-exports")),
          },
        ],
      },
      {
        path: taxManagementChapter.overview.path,
        lazy: lazyWithRetry(() => import("./financials.tax-management")),
      },
      // ...financialPeriodsRoutes,
      {
        path: financialPeriods.overview.path,
        lazy: lazyWithRetry(() => import("./financials.financial-periods")),

        children: [
          {
            path: financialPeriods.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./financials.financial-periods._index"),
                import("./financials.financial-periods._index.open"),
              ]);
              return page;
            }),
            children: [
              {
                path: financialPeriods.overview.open.path,
                lazy: lazyWithRetry(() => import("./financials.financial-periods._index.open")),
              },
            ],
          },
          {
            path: financialPeriods.details.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./financials.financial-periods.$id"),
                import("./financials.financial-periods.$id.add-income-expense.$stationId"),
                import(
                  "./financials.financial-periods.$id.create-deposit.$deviceId.$paymentTypeId.($currencyId)"
                ),
                import(
                  "./financials.financial-periods.$id.complete-deposit.$deviceId.$paymentTypeId.$depositID.($currencyId)"
                ),
                import(
                  "./financials.financial-periods.$id.open-cash-journal.$deviceId.$paymentTypeId.($currencyId)"
                ),
                import(
                  "./financials.financial-periods.$id.close-cash-journal.$deviceId.$paymentTypeId.($currencyId)"
                ),
                import("./financials.financial-periods.$id.device-ledger-details.$deviceId"),
                import(
                  "./financials.financial-periods.$id.cash-mutation.$deviceId.$paymentTypeId.($currencyId)"
                ),
                import("./financials.financial-periods.$id.close-financial-period"),
                import("./financials.financial-periods.$id.exports.$exportId"),
                import("./financials.financial-periods.$id.exports.$exportId.retry"),
                import("./financials.financial-periods.$id.transactions"),
                import(
                  "./financials.financial-periods.$id.general-ledgers.$accountId.($currencyId)"
                ),
              ]);
              return page;
            }),
            children: [
              {
                path: financialPeriods.details.addIncomeExpense.path,
                lazy: lazyWithRetry(
                  () => import("./financials.financial-periods.$id.add-income-expense.$stationId"),
                ),
              },
              {
                path: financialPeriods.details.transactions.path,
                lazy: lazyWithRetry(
                  () => import("./financials.financial-periods.$id.transactions"),
                ),
              },
              {
                path: financialPeriods.details.createDeposit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-periods.$id.create-deposit.$deviceId.$paymentTypeId.($currencyId)"
                    ),
                ),
              },
              {
                path: financialPeriods.details.completeDeposit.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-periods.$id.complete-deposit.$deviceId.$paymentTypeId.$depositID.($currencyId)"
                    ),
                ),
              },
              {
                path: financialPeriods.details.openCashJournal.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-periods.$id.open-cash-journal.$deviceId.$paymentTypeId.($currencyId)"
                    ),
                ),
              },
              {
                path: financialPeriods.details.closeCashJournal.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-periods.$id.close-cash-journal.$deviceId.$paymentTypeId.($currencyId)"
                    ),
                ),
              },
              {
                path: financialPeriods.details.deviceLedgerDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./financials.financial-periods.$id.device-ledger-details.$deviceId"),
                ),
              },
              {
                path: financialPeriods.details.cashMutation.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-periods.$id.cash-mutation.$deviceId.$paymentTypeId.($currencyId)"
                    ),
                ),
              },
              {
                path: financialPeriods.details.closeFinancialPeriod.path,
                lazy: lazyWithRetry(
                  () => import("./financials.financial-periods.$id.close-financial-period"),
                ),
              },
              {
                path: financialPeriods.details.downloadBlob.path,
                element: null,
                lazy: lazyWithRetry(async () => {
                  const route = await import("./financials.financial-periods.$id");
                  return {
                    action: route.downloadFinancialPeriodBlobAction,
                  };
                }),
              },
              {
                path: financialPeriods.details.viewExport.path,
                lazy: lazyWithRetry(
                  () => import("./financials.financial-periods.$id.exports.$exportId"),
                ),
              },
              {
                path: financialPeriods.details.retryExport.path,
                lazy: lazyWithRetry(
                  () => import("./financials.financial-periods.$id.exports.$exportId.retry"),
                ),
              },
              {
                path: financialPeriods.details.negativeStockImpedimentCreateZCC.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-period.$id.negative-stock-impediments.$productId.$ouId.create-zcc"
                    ),
                ),
              },
              {
                path: financialPeriods.details.negativeStockImpedimentUpdateStock.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-period.$id.negative-stock-impediments.$productId.$ouId.update-stock"
                    ),
                ),
              },
              {
                path: financialPeriods.details.generalLedgers.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./financials.financial-periods.$id.general-ledgers.$accountId.($currencyId)"
                    ),
                ),
              },
            ],
          },
        ],
      },

      {
        path: incomeAndExpensesChapter.overview.path,
        lazy: lazyWithRetry(() => import("./financials.income-expense._index")),
      },
      {
        path: giftcardsChapter.overview.path,
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./financials.giftcards._index")),
          },
          {
            path: giftcardsChapter.details.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./financials.giftcards.$id"),
                import("./financials.giftcards.$id.edit"),
              ]);
              return page;
            }),
            children: [
              {
                path: giftcardsChapter.details.edit.path,
                lazy: lazyWithRetry(() => import("./financials.giftcards.$id.edit")),
              },
            ],
          },
          {
            path: giftcardsChapter.new.path,
            lazy: lazyWithRetry(() => import("./financials.giftcards.new")),
          },
        ],
      },

      {
        path: generalLedgersAndAccountsChapter.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./financials.general-ledgers-and-accounts"),
            import("./financials.general-ledgers-and-accounts.accounts"),
            import("./financials.general-ledgers-and-accounts.general-ledgers"),
          ]);
          return page;
        }),
        children: [
          {
            path: generalLedgersAndAccountsChapter.generalLedgers.overview.path,
            lazy: lazyWithRetry(
              () => import("./financials.general-ledgers-and-accounts.general-ledgers"),
            ),
            children: [
              {
                path: generalLedgersAndAccountsChapter.generalLedgers.overview.exportGeneralLedgers
                  .path,
                lazy: lazyWithRetry(
                  () => import("./financials.general-ledgers-and-accounts.general-ledgers.export"),
                ),
              },
            ],
          },
          {
            path: generalLedgersAndAccountsChapter.accounts.overview.path,
            lazy: lazyWithRetry(() => import("./financials.general-ledgers-and-accounts.accounts")),
            children: [
              {
                path: generalLedgersAndAccountsChapter.accounts.overview.create.path,
                lazy: lazyWithRetry(
                  () => import("./financials.general-ledgers-and-accounts.accounts.new"),
                ),
              },
              {
                path: generalLedgersAndAccountsChapter.accounts.overview.edit.path,
                lazy: lazyWithRetry(
                  () => import("./financials.general-ledgers-and-accounts.accounts.$id.edit"),
                ),
              },
              {
                path: generalLedgersAndAccountsChapter.accounts.overview.delete.path,
                lazy: lazyWithRetry(
                  () => import("./financials.general-ledgers-and-accounts.accounts.$id.delete"),
                ),
              },
            ],
          },
        ],
      },
      {
        path: settlementsChapter.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./financials.settlements"),
            import("./financials.settlements.$id"),
            import("./financials.settlements.uploads"),
            import("./financials.settlements.uploads.new"),
          ]);
          return page;
        }),
        children: [
          {
            path: settlementsChapter.details.path,
            lazy: lazyWithRetry(() => import("./financials.settlements.$id")),
          },
          {
            path: settlementsChapter.uploads.path,
            lazy: lazyWithRetry(() => import("./financials.settlements.uploads")),
            children: [
              {
                path: settlementsChapter.uploads.delete.path,
                lazy: lazyWithRetry(() => import("./financials.settlements.uploads.$id.delete")),
              },
            ],
          },
          {
            path: settlementsChapter.uploadSettlement.path,
            lazy: lazyWithRetry(() => import("./financials.settlements.uploads.new")),
          },
        ],
      },

      ...getCookbookRecipesV2Routes(),
      ...getCookbookRecipesV1Routes(),
      {
        path: cookbookEventsChapter.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./financials.cookbook-events"),
            import("./financials.cookbook-events.reset"),
          ]);
          return page;
        }),
        children: [
          {
            path: cookbookEventsChapter.resetEvents.path,
            lazy: lazyWithRetry(() => import("./financials.cookbook-events.reset")),
          },
          {
            path: cookbookEventsChapter.processEvents.path,
            lazy: lazyWithRetry(() => import("./financials.cookbook-events.process")),
          },
        ],
      },

      /**
       * EVA Intent Handler
       */
      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
    ],
  },
];
