import { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouteObject,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

import { legacyTasksModuleRedirecterRouteObject } from "./tasks/legacy-tasks-module-redirecter";
import { authRoutes } from "./__auth";
import { complianceRoutes } from "./compliance";
import { controlRoomRoutes } from "./control-room";
import { dashboardRoutes } from "./dashboard";
import { evaIntentHandlerRoute } from "./eva-intent-handler";
import { financialsRoutes } from "./financials";
import { ordersRoutes } from "./orders";
import { organizationsRoutes } from "./organizations";
import { peopleRoutes } from "./people";
import { pimRoutes } from "./pim";
import { profileRoutes } from "./profile";
import { promotionsRoutes } from "./promotions";
import { resourceRoutes } from "./resource";
import { rootLoader, RootRoute } from "./root";
import { stockManagementRoutes } from "./stock-management";
import { tasksRoutes } from "./tasks";

import { ErrorFallback } from "~/components/shared/error-fallback";
import { queryClient } from "~/util/query-client";

const BLOCK_SENTRY_URL_STARTS = [
  "http://localhost",
  "https://deploy-preview",
  "https://feature",
  "https://bugfix",
  "https://bug-fix",
  "https://chore",
  "https://release-fix",
  "https://dependabot",
];

// Setup Sentry with React Router DOM
const integrations: Sentry.BrowserOptions["integrations"] = () => {
  const newIntegrations: Sentry.BrowserOptions["integrations"] = [];

  const browserTracing = Sentry.reactRouterV6BrowserTracingIntegration({
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  });

  newIntegrations.push(browserTracing);

  return newIntegrations;
};

// Setup Sentry with React Router DOM
Sentry.init({
  dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
  release: `${import.meta.env.VITE_SENTRY_RELEASE}`,
  enabled:
    import.meta.env.PROD && !BLOCK_SENTRY_URL_STARTS.some((url) => import.meta.url.startsWith(url)),
  integrations,
  tracesSampleRate: 0.2,
});
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <ErrorFallback fullPage />,
    loader: rootLoader(queryClient),
    id: "root",
    children: [
      ...authRoutes,
      ...dashboardRoutes,
      ...profileRoutes,
      ...organizationsRoutes,
      ...peopleRoutes,
      ...controlRoomRoutes,
      ...financialsRoutes,
      ...pimRoutes,
      ...ordersRoutes,
      ...promotionsRoutes,
      ...stockManagementRoutes,
      ...complianceRoutes,
      ...tasksRoutes,
      ...resourceRoutes,
      evaIntentHandlerRoute,
      legacyTasksModuleRedirecterRouteObject,
    ],
  },
];

const router = sentryCreateBrowserRouter(routes, {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
  },
});

export const Routes = () => {
  return (
    <RouterProvider
      router={router}
      future={
        {
          // v7_startTransition: true,
        }
      }
    />
  );
};
