import { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";

import { helpers } from "@springtree/eva-sdk-react-recoil";

import { logoutService } from "~/models/auth";
import routeDefinitions from "~/routes/route-definitions";
import { queryClient } from "~/util/query-client";

const { storage } = helpers;

export default function LogoutPage() {
  const isLoggingOut = useRef(false);

  useEffect(() => {
    if (!isLoggingOut.current) {
      isLoggingOut.current = true;
      (async () => {
        const currentUserToken = storage.getItem("evaUserToken");

        // if there is a token, call the logout service
        if (currentUserToken) {
          await logoutService.call({});
        }
      })();

      // clear the token and requested organization unit id
      storage.removeItem("evaUserToken");
      storage.setItem("evaRequestedOrganizationUnitID", "");

      // clear the query client
      queryClient.clear();

      // Hard redirect to the login page. Opting out of react-router-dom's navigation
      setTimeout(() => {
        window.location.replace(routeDefinitions.auth.login.path);
      }, 100);
    }
  }, []);
  return <Outlet />;
}
