import { RouteObject } from "react-router-dom";

import lazyWithRetry from "~/routes/lazy-with-retry";
import routeDefinitions from "~/routes/route-definitions";

const { dashboard } = routeDefinitions;

// Parent is root /
export const dashboardRoutes: RouteObject[] = [
  {
    path: dashboard.wrapper.path,
    lazy: lazyWithRetry(() => import("./dashboard")),
    children: [
      {
        path: dashboard.search.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./dashboard.search"),
            import("./dashboard.search.info"),
            import("./dashboard.search.old-admin-survey"),
          ]);
          return page;
        }),
        children: [
          {
            path: dashboard.search.info.path,
            lazy: lazyWithRetry(() => import("./dashboard.search.info")),
          },
          {
            path: dashboard.search.oldAdminSurvey.path,
            lazy: lazyWithRetry(() => import("./dashboard.search.old-admin-survey")),
          },
        ],
      },
      {
        path: dashboard.overview.path,
        lazy: lazyWithRetry(() => import("./dashboard.overview")),
      },
      {
        path: dashboard.lyraLaunch.path,
        lazy: lazyWithRetry(() => import("./dashboard.lyra-launch")),
      },
    ],
  },
];
