import { adminSuiteConfig } from "../../../../admin-suite.config";

import routeDefinitions from "~/routes/route-definitions";
import { IRouteDefinition } from "~/types/route-definitions";

export const SHIP_FROM_STORE_TASKS_QUERY_KEY = "ship-from-store";

export const DEFAULT_SHIP_FROM_STORE_TASKS_REQUEST = {
  UserTaskTypes: ["CompositeUserTask", "ShipFromStore"],
};

export enum SfsTaskSubtype {
  Pick = "Pick",
  Ship = "Ship",
  Print = "Print",
  Deliver = "Deliver",
  Pack = "Pack",
}

export type SfsTaskSubtypeWithBarcodeFilter = SfsTaskSubtype.Pick | SfsTaskSubtype.Ship;

export type SupportedSfsTaskSubtypes = SfsTaskSubtype;

const getOrderedSfsTaskSubtypes = () => {
  const orderdSfsTaskSubtypes = [] as SupportedSfsTaskSubtypes[];

  orderdSfsTaskSubtypes.push(SfsTaskSubtype.Pick);

  if (adminSuiteConfig.packTasks) {
    orderdSfsTaskSubtypes.push(SfsTaskSubtype.Pack);
  }

  orderdSfsTaskSubtypes.push(SfsTaskSubtype.Ship);
  orderdSfsTaskSubtypes.push(SfsTaskSubtype.Print);

  if (adminSuiteConfig.deliverTasks) {
    orderdSfsTaskSubtypes.push(SfsTaskSubtype.Deliver);
  }

  return orderdSfsTaskSubtypes;
};

export const ORDERED_SFS_TASK_SUBTYPES: SupportedSfsTaskSubtypes[] = getOrderedSfsTaskSubtypes();

export const SFS_TASK_SUBTYPE_ROUTE_DEFINITIONS: Record<
  SupportedSfsTaskSubtypes,
  IRouteDefinition
> = {
  [SfsTaskSubtype.Pick]: routeDefinitions.tasks.shipFromStore.pick.overview,
  [SfsTaskSubtype.Ship]: routeDefinitions.tasks.shipFromStore.ship.overview,
  [SfsTaskSubtype.Print]: routeDefinitions.tasks.shipFromStore.print.overview,
  [SfsTaskSubtype.Deliver]: routeDefinitions.tasks.shipFromStore.deliver.overview,
  [SfsTaskSubtype.Pack]: routeDefinitions.tasks.shipFromStore.pack.overview,
};
